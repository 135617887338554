import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { StaticImage } from "gatsby-plugin-image"
import { graphql } from "gatsby"

import { GatsbyImage } from "gatsby-plugin-image"
import { Wrapper } from '../../common/wrapper'
import { Container } from '../../common/container'
import { Heading } from '../../common/heading'
import { StyledLink } from '../../common/link'

import { useLocation } from '@reach/router';
import { device  } from '../../../utils/breakpoints'
import Layout from '../../layout/index'




const StyledWrapper = styled(Wrapper) `
    background: ${({ theme }) => theme.colors.grayWhite};
`

const StyledContainer = styled(Container) `
    gap: 2rem;
    justify-content: center;
`

const ContainerArticle = styled.div `
    display: grid;
    grid-template-columns: auto;
    align-content: center;
    gap: 2rem;
    background: ${({ theme }) => theme.colors.white};
    max-width: 900px;
    padding: 0.5rem;
    border-radius: 5px;

    @media ${device.mobileXL} { 
        padding: 2rem;
    }
    
    p:last-child {
        margin-top: 3rem;
    }

    h2 {
        margin: 2rem 0;
        font-size: ${({ theme }) => theme.fonts.size.m};
        line-height: 150%;
        text-align: center;

        @media ${device.tablet} {
            font-size: ${({ theme }) => theme.fonts.size.l};
        }
    }

    a {
        color: ${({ theme }) => theme.colors.black};
    }
  

    b {
        font-weight: 500;
    }

    s {
        margin-top: 2rem;
        font-size: ${({ theme }) => theme.fonts.size.xs};
    }
    
`

const ContainerInformationBlog = styled.div `
    display: grid;
    gap: 0.5rem;
`
const ContainerInformation = styled.div `
    display: grid;
    gap: 2rem;
`


const ContainerAutor = styled.div `
    display: grid;
    gap: 2rem;
    grid-template-columns: auto auto;
    justify-content: start;

    div {
        display: grid;
        gap: 1rem;
        align-content: center;

        span {
            font-weight: 300;
            color: gray;
        }
    }

`
const ContainerImage = styled.div `
    position: relative;
    background: blue;
    width: 100px;
    height: 100px;
    border-radius: 100px;

    .image-styles {
        border-radius: 100px;
    }
`

const Img = styled(GatsbyImage) `
    position: absolute;
    height: 100%;
    width: 100%;
    object-fit: cover;

`

const Name = styled.div `
    font-size: 25px;

`

const StyledHeading = styled(Heading) `
    font-weight: 500;
    color: ${({ theme }) => theme.colors.green};
`
const CategoryName = styled.div `
    text-transform: uppercase;
    font-size: ${({ theme }) => theme.fonts.size.xxs};
`

const Article = ({ data }) => {

    const post = data.contentfulBlogPost
    const location = useLocation()
    const [currentPath, setCurrentPath] = useState(location.pathname)
    


    useEffect(() => {
        setCurrentPath(location.pathname);
      }, [location]);

    console.log(location.pathname)  

    

    if (currentPath === `/torun-blog/${post.slug}/`|| `/torun-blog/${post.slug}/`) {
        return (
            <Layout torun>
                <StyledWrapper>
                    <StyledContainer>
                        <ContainerInformationBlog>
                            <CategoryName>{post.kategoria}</CategoryName>
                            <ContainerInformation>
                                <StyledHeading h1 as='h1'>{post.tytul}</StyledHeading>
                                <ContainerAutor>
                                    <ContainerImage>
                                        <Img imgClassName="image-styles" image={post.zdjecieAutora?.gatsbyImageData} />
                                    </ContainerImage>
                                    <div>
                                        <Name>{post.autor}</Name>
                                        <span>{post.data}</span>
                                    </div>
                                </ContainerAutor>
                            </ContainerInformation>
                        </ContainerInformationBlog>
                        
                        <ContainerArticle>
                            <div dangerouslySetInnerHTML={ {__html: post.tresc.childMarkdownRemark.html}}/>
                        </ContainerArticle>   
                    </StyledContainer>
                </StyledWrapper>
            </Layout>
        )
    } else if(currentPath === `/lodz-blog/${post.slug}/` || `/lodz-blog/${post.slug}/`) {
        return (
            <Layout lodz>
                <StyledWrapper>
                    <StyledContainer>
                        <ContainerInformationBlog>
                            <CategoryName>{post.kategoria}</CategoryName>
                            <ContainerInformation>
                                <StyledHeading h1 as='h1'>{post.tytul}</StyledHeading>
                                <ContainerAutor>
                                    <ContainerImage>
                                        <Img imgClassName="image-styles" image={post.zdjecieAutora?.gatsbyImageData} />
                                    </ContainerImage>
                                    <div>
                                        <Name>{post.autor}</Name>
                                        <span>{post.data}</span>
                                    </div>
                                </ContainerAutor>
                            </ContainerInformation>
                        </ContainerInformationBlog>
                        
                        <ContainerArticle>
                            <div dangerouslySetInnerHTML={ {__html: post.tresc.childMarkdownRemark.html}}/>
                        </ContainerArticle>   
                    </StyledContainer>
                </StyledWrapper>
            </Layout>
        )
    } else if(currentPath === `/bydgoszcz-blog/${post.slug}/`|| `/bydgoszcz-blog/${post.slug}/`) {
        return (
            <Layout bydgoszcz>
                <StyledWrapper>
                    <StyledContainer>
                        <ContainerInformationBlog>
                            <CategoryName>{post.kategoria}</CategoryName>
                            <ContainerInformation>
                                <StyledHeading h1 as='h1'>{post.tytul}</StyledHeading>
                                <ContainerAutor>
                                    <ContainerImage>
                                        <Img imgClassName="image-styles" image={post.zdjecieAutora?.gatsbyImageData} />
                                    </ContainerImage>
                                    <div>
                                        <Name>{post.autor}</Name>
                                        <span>{post.data}</span>
                                    </div>
                                </ContainerAutor>
                            </ContainerInformation>
                        </ContainerInformationBlog>
                        
                        <ContainerArticle>
                            <div dangerouslySetInnerHTML={ {__html: post.tresc.childMarkdownRemark.html}}/>
                        </ContainerArticle>   
                    </StyledContainer>
                </StyledWrapper>
            </Layout>
        )
    }
    
    
}

export default Article

export const query = graphql`
  query ($slug: String) {
    contentfulBlogPost( slug: { eq: $slug }) {
      tytul
      tresc {
        childMarkdownRemark {
          html
        }
      }
      zdjecieAutora {
        gatsbyImageData(layout: CONSTRAINED)
        title
        description
      }
      slug
      kategoria
      autor
      data
      seoTitle
      seoDescription
    }
  }
`